export const getCorrectDate = (date) => {
  const tmpDate = new Date(date).setHours(0, 0, 0, 0);
  const timeZone = new Date(date).getTimezoneOffset() * 60000;
  return new Date(tmpDate - timeZone);
};

export const arrayToChunks = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result;
};
