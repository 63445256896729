import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from 'reapop';

import Food from '../../components/Food/index';
import onSite from '../../assets/images/onsite.png';
import takeOut from '../../assets/images/takeout.png';
import Section from '../../components/Section/Section';
import { ADecrementMeals, AIncrementMeals, cancelOrder, getUserOrders, makeOrder } from '../../redux/actions/userOrdersActions';
import { userLogout } from '../../redux/actions/userActions';
import { arrayToChunks } from '../../utils';

const Orders = () => {
  const windowSize = useRef({ width: window.innerWidth, height: window.innerHeight });

  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);

  const loading = useSelector((state) => state.userOrders.loadings.get);
  const menus = useSelector((state) => state.userOrders.menus);

  const dispatch = useDispatch();

  const [chosenMenu, setChosenMenu] = useState();
  const [chosenFood, setChosenFood] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!loading && user) {
      dispatch(getUserOrders(user.id));
    }
  }, [user]);

  useEffect(() => {
    if (!loading) {
      setChosenMenu(undefined);
      setChosenFood(undefined);
      setShowModal(false);
    }
  }, [loading]);

  const handleOpenModal = (menu, food) => {
    setChosenMenu(menu);
    setChosenFood(food);
    setShowModal(true);
  };

  const handleOrderFood = (takeOut) => {
    dispatch(makeOrder(token, user.id, chosenMenu, chosenFood, takeOut));
  };

  const handleCancelFoodOrder = async (menu, food) => {
    dispatch(cancelOrder(token, user.id, menu, food));
  };

  const balance = user && user.credit ? Math.round((user.credit.balance - user.credit.bill) * 100) / 100 : 0;

  const menusArray = Object.values(menus).sort((a, b) => new Date(a.day) - new Date(b.day));

  const renderFoodsByType = (menu, type, maxCount) => {
    const foods = menu && menu.foodIds.length ? menu.foodIds.filter((f) => f.type === type) : [];
    if (foods.length) {
      let heading;
      switch (type) {
        case 'breakfast':
          heading = 'SNÍDANĚ';
          break;
        case 'lunch':
          heading = 'OBĚDY';
          break;
        case 'dinner':
          heading = 'VEČEŘE';
          break;
        default:
          break;
      }

      const chunks = arrayToChunks(foods, maxCount);

      return (
        <Container fluid key={`menu_${menu.id}_type_${type}`} className="p-0">
          <h5 className="text-center">{heading}</h5>
          {chunks.map((rowFoods, rowIndex) => (
            <Row key={`menu_row_${rowIndex}`}>
              {rowFoods.map((food) => {
                const order = menu.order;
                const totalFoodsInOrder = order ? order.foodInfo.length : 0;
                const orderedFoods = order ? order.foodInfo.filter((item) => item.id === food.id) : [];
                const orderedFoodsCount = orderedFoods.length;
                const takeoutCount = orderedFoods.filter((item) => item.takeout === true).length;
                const onsiteCount = orderedFoods.filter((item) => item.takeout === false).length;
                let action;
                if (new Date() > new Date(menu.orderTimeLimit)) {
                  if (new Date(menu.day).setHours(0, 0, 0) <= new Date() && new Date() <= new Date(menu.day).setHours(24, 0, 0)) {
                    action = () => {
                      dispatch(
                        notify({
                          title: 'Dnešní objednávka',
                          message: 'S dnešní objednávkou nejsou možné žádné úpravy!',
                          status: 'warning',
                          dismissAfter: 3000,
                        }),
                      );
                    };
                  } else {
                    // normální den
                    action = () => {
                      dispatch(
                        notify({
                          title: 'Objednávka',
                          message: 'S objednávkou jsou možné úpravy pouze do 22:00 hodin předchozího dne!',
                          status: 'warning',
                          dismissAfter: 3000,
                        }),
                      );
                    };
                  }
                } else if (!order) {
                  action = () => {
                    handleOpenModal(menu, food);
                  };
                } else if (order) {
                  if (totalFoodsInOrder >= menu.foodCount) {
                    if (orderedFoodsCount > 0) {
                      action = () => handleCancelFoodOrder(menu, food);
                    } else {
                      action = () => {
                        dispatch(
                          notify({
                            title: 'Počet jídel',
                            message: 'K objednávce nelze přidat další jídlo! Zvyšte počet jídel na den.',
                            status: 'warning',
                            dismissAfter: 3000,
                          }),
                        );
                      };
                    }
                  } else {
                    action = () => {
                      handleOpenModal(menu, food);
                    };
                  }
                }

                return (
                  <Col key={`menu_${menu.id}_${type}_food_${food.id}`}>
                    <Food data={food} onsiteCount={onsiteCount} takeoutCount={takeoutCount} action={action} />
                  </Col>
                );
              })}
              {rowFoods.length < maxCount ? [...Array(maxCount - rowFoods.length)].map((_, i) => <Col key={`empty_col_${menu.id}_${type}_${i}`} />) : null}
            </Row>
          ))}
        </Container>
      );
    }
  };

  return (
    <div id="orders">
      {user && (
        <div className="loggedUser">
          <div>
            <span>Přihlášen: {`${user.firstname} ${user.surname} (${balance} Kč)`}</span>
            <Button variant="outline-dark" onClick={() => dispatch(userLogout())}>
              Odhlásit se
            </Button>
          </div>
        </div>
      )}
      {menusArray.length > 0 ? (
        menusArray.map((menu) => {
          return menu.info ? (
            <Section key={`menu_${menu.id}`} heading={moment(menu.day).format('dddd D.M.')}>
              <p className="text-center">{menu.info}</p>
            </Section>
          ) : (
            <Section
              key={`menu_${menu.id}`}
              heading={moment(menu.day).format('dddd D.M.')}
              count={menu.foodCount}
              counterHandlers={{
                handleIncrementMeal: () => dispatch(AIncrementMeals(menu)),
                handleDecrementMeal: () => dispatch(ADecrementMeals(menu)),
              }}>
              {['breakfast', 'lunch', 'dinner'].map((type) => renderFoodsByType(menu, type, windowSize.current.width < 576 ? 2 : 4))}
            </Section>
          );
        })
      ) : (
        <Section heading="SEZNAM MENÍČEK">
          <p className="text-center">Meníčka na následující dny nejsou k dispozici.</p>
        </Section>
      )}
      {chosenMenu && chosenFood ? (
        <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Budete jíst u nás nebo chcete jídlo s sebou?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 className="text-center p-4">{chosenFood.name}</h3>
            <Row>
              <Col>
                <Food data={{ longName: 'Konzumace na místě' }} image={onSite} action={() => handleOrderFood(false)} />
              </Col>
              <Col>
                <Food data={{ longName: 'Zabalit s sebou' }} image={takeOut} action={() => handleOrderFood(true)} />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={() => setShowModal(false)}>
              Zavřít
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  );
};

export default Orders;
